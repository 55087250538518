import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import ReactFlow, { addEdge, MiniMap, Controls, Background, applyNodeChanges, applyEdgeChanges, useReactFlow, ReactFlowProvider } from 'reactflow';
import Cookies from 'js-cookie';  // Add this import
import CustomNode from './CustomNode';
import CustomToolbar from './CustomToolbar';
import Tooltip from './Tooltip';  // Add this import
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';  // Add this import

//Reactflow style overlays
import 'reactflow/dist/style.css';

// Initial nodes including the default node
const initialElements = [
  {
    id: '1',
    type: 'custom',
    position: { x: 250, y: 5 },
    data: {
      title: 'Default Node',
      description: 'This node cannot be deleted',
      asset_type: 'App',
      uid: '1'  // Add this to ensure proper config loading
    },
    deletable: false,
  },
];

const Flow = ({ app_metadata, appConfig, onAppConfigUpdate, onAddItem, onDeleteItem, inactiveStatus }) => {
  const [nodes, setNodes] = useState(initialElements);
  const [edges, setEdges] = useState([]);
  const [nodeId, setNodeId] = useState(2);
  const [selectedNode, setSelectedNode] = useState('1'); // Default to primary app node
  const reactFlowWrapper = useRef(null);
  const { project } = useReactFlow();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipDimensions, setTooltipDimensions] = useState({ width: 400, height: 600 });
  const [minTooltipHeight, setMinTooltipHeight] = useState(400);
  const [previewedNodes, setPreviewedNodes] = useState({});
  const [previewData, setPreviewData] = useState({}); // Add state for preview data

  const toggleTooltip = useCallback(() => {
    setTooltipVisible(prev => !prev);
  }, []);

  const onTooltipHeightChange = useCallback((height) => {
    setMinTooltipHeight(Math.max(400, height));
  }, []);

  const [tooltipPosition, setTooltipPosition] = useState(() => {
    // Calculate initial position: centered in the right half of the screen
    const screenWidth = window.innerWidth;
    const tooltipWidth = 400; // default width
    const padding = 20;
    
    return { 
      x: Math.max(padding, Math.min(screenWidth - tooltipWidth - padding, screenWidth * 0.6)), 
      y: 100  // Fixed distance from top
    };
  });

  // Add this function to handle window resize
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const tooltipWidth = tooltipDimensions.width;
      const padding = 20;

      setTooltipPosition(prev => ({
        ...prev,
        x: Math.max(padding, Math.min(screenWidth - tooltipWidth - padding, prev.x))
      }));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [tooltipDimensions.width]);

  // Function to add and connect a new custom node only when explicitly requested
  const addNewCustomNode = useCallback((title, description, asset_type) => {
    // This function will now only be called when the user clicks the "eye" icon
    const newNodeId = `node-${nodeId}`;
    const newNode = {
      id: newNodeId,
      type: 'custom',
      data: {
        title: title || nodeId,
        description: description || '',
        asset_type: asset_type || 'App'
      },
      position: {
        x: Math.random() * 500,
        y: Math.random() * 500
      },
    };

    const newEdge = {
      id: `e1-${newNodeId}`,
      source: '1',
      target: newNodeId,
      animated: true,
      style: {
        stroke: 'black',
        strokeDasharray: '5,5'
      },
    };

    setNodes((nds) => [...nds, newNode]);
    setEdges((eds) => [...eds, newEdge]);
    setNodeId((prevId) => prevId + 1);
  }, [nodeId]);

  // Modify onNodesChange to handle selection and tooltip visibility
  const onNodesChange = useCallback((changes) => {
    changes.forEach((change) => {
      if (change.type === 'select') {
        setSelectedNode(change.id);
      }
    });
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const onConnect = useCallback((params) => {
    setEdges((eds) => addEdge(params, eds));
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback((event) => {
    event.preventDefault();
    
    try {
        const schemaData = JSON.parse(event.dataTransfer.getData('application/json'));
        if (onAppConfigUpdate) {
            const updatedConfig = [...(appConfig || []), schemaData];
            onAppConfigUpdate(updatedConfig);
        }
    } catch (error) {
        console.error('Drop error:', error);
    }
}, [appConfig, onAppConfigUpdate]);

  // Handle click outside of nodes to hide tooltip
  const onPaneClick = useCallback(() => {
    setSelectedNode(null);
    setTooltipVisible(false);
  }, []);

  // Update initial nodes to include selected state
  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => ({
        ...node,
        selected: node.id === '1',
      }))
    );
  }, []);

  // Memoize nodeTypes to prevent recreation on every render
  const nodeTypes = useMemo(() => ({
    custom: (props) => (
      <CustomNode
        {...props}
        isSelected={props.id === selectedNode}
        app_metadata={app_metadata}
        appConfig={appConfig}
        onAppConfigUpdate={onAppConfigUpdate}
        onAddItem={onAddItem}
        onDeleteItem={onDeleteItem}
        addNewCustomNode={addNewCustomNode}
      />
    ),
  }), [app_metadata, appConfig, onAppConfigUpdate, onAddItem, onDeleteItem, addNewCustomNode, selectedNode]);

  // Add monitoring effect
  useEffect(() => {
    console.log('ReactFlow config changed:', appConfig);
  }, [appConfig]);

  // Helper function to clean UID
  const cleanUid = (uid) => {
    // Remove '/a/' if present and any leading/trailing slashes
    return uid.replace(/^\/a\//, '').replace(/^\/+|\/+$/g, '');
  };

  // Add function to create preview node
  const handlePreviewNode = useCallback(async (item) => {
    const itemId = item.id;

    setPreviewedNodes(prev => {
        const isCurrentlyVisible = prev[itemId];
        
        if (isCurrentlyVisible) {
            // Hide preview
            setNodes(nodes => nodes.filter(node => node.id !== `preview-${itemId}`));
            setEdges(edges => edges.filter(edge => edge.target !== `preview-${itemId}`));
            
            // Update appConfig preview state
            onAppConfigUpdate(appConfig.map(section => ({
                ...section,
                schema: section.schema?.map(schemaItem => 
                    schemaItem.id === itemId 
                        ? { ...schemaItem, isPreviewVisible: false }
                        : schemaItem
                )
            })));
            
            const { [itemId]: removed, ...rest } = prev;
            return rest;
        }

        // Show preview with proper error handling
        if (item.asset_type === 'App') {
            const cleanedUid = cleanUid(item.to);
            const authToken = Cookies.get('auth_token');

            fetch(`/api/get-app/${cleanedUid}/`, {
                headers: { 'Authorization': `Token ${authToken}` }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setPreviewData(prevData => ({
                    ...prevData,
                    [cleanedUid]: data
                }));
                addPreviewNode(item, itemId, data);
            })
            .catch(error => {
                console.error('Error fetching app data:', error);
                // Optionally show error state in UI
            });
        } else {
            addPreviewNode(item, itemId);
        }

        return { ...prev, [itemId]: true };
    });
}, [nodes, appConfig, onAppConfigUpdate]);

  // Helper function to add preview node
  const addPreviewNode = (item, itemId, fetchedData = null) => {
    const existingNodes = nodes.filter(n => n.id !== '1').length;
    const cleanedUid = cleanUid(item.to);

    const newNode = {
        id: `preview-${itemId}`,
        type: 'custom',
        data: {
            title: item.name,
            description: item.description,
            asset_type: item.asset_type,
            uid: cleanedUid,
            previewData: item.asset_type === 'App' ? (fetchedData || previewData[cleanedUid]) : null
        },
        position: {
            x: 600 + (existingNodes * 50),
            y: 5 + (existingNodes * 50)
        },
    };

    const newEdge = {
        id: `e1-${newNode.id}`,
        source: '1',
        target: newNode.id,
        animated: true,
        style: { stroke: '#6366f1', strokeWidth: 2, strokeDasharray: '5,5' }
    };

    setNodes(nodes => [...nodes, newNode]);
    setEdges(edges => [...edges, newEdge]);

    // Update appConfig preview state
    onAppConfigUpdate(appConfig.map(section => ({
        ...section,
        schema: section.schema?.map(schemaItem => 
            schemaItem.id === itemId 
                ? { ...schemaItem, isPreviewVisible: true }
                : schemaItem
        )
    })));
};

  // Reset preview states on load/reload
  useEffect(() => {
    if (appConfig) {
      // Reset all preview states to false
      onAppConfigUpdate(appConfig.map(section => ({
        ...section,
        schema: section.schema?.map(schemaItem => ({
          ...schemaItem,
          isPreviewVisible: false
        }))
      })));
      
      // Clear any preview nodes
      setNodes(nodes => nodes.filter(node => !node.id.startsWith('preview-')));
      setEdges(edges => edges.filter(edge => !edge.target.startsWith('preview-')));
      setPreviewedNodes({});
      setPreviewData({});
    }
  }, []); // Empty dependency array to run only on mount/reload

  return (
    <div className="flex relative" style={{ height: '100vh' }} ref={reactFlowWrapper}>
      <CustomToolbar 
        onToggleTooltip={toggleTooltip} 
        isTooltipVisible={tooltipVisible}
      />
      
      {tooltipVisible && (
        <div
          style={{
            position: 'absolute',
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
            zIndex: 9999,
          }}
        >
          <ResizableBox
            width={tooltipDimensions.width}
            height={tooltipDimensions.height}
            minConstraints={[300, minTooltipHeight]}
            maxConstraints={[800, 1000]}
            handleSize={[8, 8]}
            resizeHandles={['se']}
            onResize={(e, data) => {
              setTooltipDimensions({
                width: data.size.width,
                height: Math.max(data.size.height, minTooltipHeight)
              });
            }}
          >
            <Tooltip
              appConfig={appConfig}
              onUpdateAppConfig={onAppConfigUpdate}
              onAddItem={onAddItem}
              onDeleteItem={onDeleteItem}
              position={tooltipPosition}
              onPositionChange={setTooltipPosition}
              dimensions={tooltipDimensions}
              onHeightChange={onTooltipHeightChange}
              onPreviewNode={handlePreviewNode}
            />
          </ResizableBox>
        </div>
      )}

      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onPaneClick={onPaneClick}
        nodeTypes={nodeTypes}
        fitView
        className="react-flow"
        connectionLineType="straight"
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <MiniMap nodeColor={(node) => (node.id === '1' ? '#ffcc00' : '#00ccff')} />
        <Controls />
        <Background color="#aaa" gap={16} />

        {inactiveStatus && (
          <div className="absolute inset-x-0 bottom-4 flex justify-center z-50 pointer-events-none">
            <div className="bg-slate-600 px-8 py-4 rounded-lg shadow-xl">
              <p className="text-sm/6 text-white text-center">
                <strong className="font-semibold">
                  This app is currently disabled for public users
                </strong>
              </p>
            </div>
          </div>
        )}
      </ReactFlow>
    </div>
  );
};

// Wrap the main component with ReactFlowProvider
const ReactFlowEditor = (props) => {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  );
};

export default ReactFlowEditor;