import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { IconMap } from '../config/IconMap';
import ReusableModal from '../components/editor_components/ReusableModal/ReusableModal';
import Notification from '../components/Notifications/Notifications';

export default function ProductEditor({ context }) {
    const { uid } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [showNotification, setShowNotification] = useState(false);

    const [error, setError] = useState(null);
    const [product, setProduct] = useState(null);
    const [images, setImages] = useState([]);
    const [productName, setProductName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [discount, setDiscount] = useState('');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
    const [isCreatingNewCategory, setIsCreatingNewCategory] = useState(true);
    const [newCategory, setNewCategory] = useState({ title: '', description: '' });
    const [generalError, setGeneralError] = useState("");
    const [initialCategories, setInitialCategories] = useState([]);

    const [fieldValues, setFieldValues] = useState([]);
    const [variants, setVariants] = useState([]);
    const [currentVariant, setCurrentVariant] = useState(null);
    const [isVariantModalOpen, setIsVariantModalOpen] = useState(false);
    const [variantFieldValues, setVariantFieldValues] = useState({
        sku: '',
        price: '',
        stock_quantity: '',
        tempId: '',
        attributes: [] // Add this
    });

    const triggerNotification = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    };

    // Fetch product data
    useEffect(() => {
        fetchData();
    }, [uid]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const token = Cookies.get('auth_token');

            // Fetch product details
            const productResponse = await axios.get(`/api/product/${uid}`, {
                headers: { Authorization: `Token ${token}` },
            });
            const productData = productResponse.data;

            // Fetch all categories
            const categoryResponse = await axios.get('/api/categories', {
                headers: { Authorization: `Token ${token}` },
            });
            const allCategories = categoryResponse.data.categories;

            // Map product's categories to category IDs
            const productCategoryIds = allCategories
                .filter((cat) => productData.categories.includes(cat.title))
                .map((cat) => cat.id);

            setProduct(productData);
            setProductName(productData.title);
            setDescription(productData.description);
            setPrice(productData.price);
            setDiscount(productData.discount);
            setImages(productData.images || []);
            setCategories(allCategories);
            setSelectedCategories(productCategoryIds);
            setVariants(productData.variants || []);
            setHasUnsavedChanges(false);

        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFieldChange = (setter) => (e) => {
        setter(e.target.value);
        setHasUnsavedChanges(true);
    };

    // Function to fetch categories from API
    const fetchCategories = async () => {
        const token = Cookies.get('auth_token');
        try {
            const response = await axios.get('/api/categories', {
                headers: { Authorization: `Token ${token}` },
            });
            setCategories(response.data.categories); // Access the categories array within the response data
        } catch (error) {
            setGeneralError("Failed to load categories.");
        }
    };

    // Toggle between creating a new category and selecting existing ones
    const toggleCategoryModalView = () => {
        setIsCreatingNewCategory((prev) => !prev); // Toggle the creation mode
    };

    // Open category modal in "Select Existing" mode
    const openCategoryModal = () => {
        fetchCategories();
        setIsCreatingNewCategory(false);
        setCategoryModalOpen(true);
        setFieldValues(selectedCategories); // Ensure fieldValues is an array of selected category IDs
    };

    const openVariantModal = (variant = null) => {
        if (variant) {
            setCurrentVariant(variant);
            setVariantFieldValues({
                sku: variant.sku,
                price: variant.price,
                stock_quantity: variant.stock_quantity,
                tempId: variant.tempId || Date.now(),
                attributes: variant.attributes || [] // Add this
            });
        } else {
            setCurrentVariant(null);
            setVariantFieldValues({
                sku: '',
                price: '',
                stock_quantity: '',
                tempId: Date.now(),
                attributes: [] // Add this
            });
        }
        setIsVariantModalOpen(true);
    };    

    const handleAddAttribute = () => {
        setVariantFieldValues(prev => ({
            ...prev,
            attributes: [...prev.attributes, { title: '', value: '' }]
        }));
    };

    const handleAttributeChange = (index, field, value) => {
        setVariantFieldValues(prev => {
            const newAttributes = [...prev.attributes];
            newAttributes[index] = { 
                ...newAttributes[index],
                [field]: value 
            };
            return {
                ...prev,
                attributes: newAttributes
            };
        });
    };

    const handleRemoveAttribute = (index) => {
        setVariantFieldValues(prev => ({
            ...prev,
            attributes: prev.attributes.filter((_, i) => i !== index)
        }));
    };

    // Save or Select Category based on modal state
    const handleSaveCategory = async (newCategory) => {
        const token = Cookies.get('auth_token');
        try {
            const response = await axios.post('/api/categories', newCategory, {
                headers: { Authorization: `Token ${token}` },
            });
            setCategories((prev) => [...prev, response.data]);
            setSelectedCategories((prev) => [...prev, response.data.id]);
            setCategoryModalOpen(false);
            setNewCategory({ title: '', description: '' });
            setHasUnsavedChanges(true);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Display inline validation error for duplicate title
                setGeneralError(error.response.data.title || 'Failed to save category');
            } else {
                setGeneralError("An unexpected error occurred.");
            }
        }
    };

    // Toggle existing category selection
    const toggleCategorySelection = (categoryId) => {
        setSelectedCategories((prevSelected) =>
            prevSelected.includes(categoryId)
                ? prevSelected.filter((id) => id !== categoryId)
                : [...prevSelected, categoryId]
        );
        setHasUnsavedChanges(true);
    };

    // Handle save operation for the variant modal
    const handleSaveVariant = () => {
        console.log("Current variant fields:", variantFieldValues);

        // Validate fields
        if (!variantFieldValues.price || !variantFieldValues.stock_quantity) {
            console.log("Missing required fields");
            return;
        }

        setVariants(prev => {
            const newVariant = {
                ...variantFieldValues,
                tempId: variantFieldValues.tempId || Date.now().toString()
            };
            console.log("Adding/updating variant:", newVariant);

            if (currentVariant) {
                // Update existing
                return prev.map(v => v.tempId === currentVariant.tempId ? newVariant : v);
            } else {
                // Add new
                return [...prev, newVariant];
            }
        });

        setIsVariantModalOpen(false);
        setCurrentVariant(null);
        setHasUnsavedChanges(true);
    };


    // Handle image upload with preview and 3-image limit
    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files).slice(0, 3 - images.length);
        const newImages = files.map((file) => ({ image_url: URL.createObjectURL(file), file }));
        setImages((prevImages) => prevImages.concat(newImages).slice(0, 3));
        setHasUnsavedChanges(true);
    };

    // Handle image removal
    const handleRemoveImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setHasUnsavedChanges(true);
    };

    const handleAddVariant = () => {
        openVariantModal();
    };

    // Handle saving the entire product
    const handleSaveProduct = async () => {
        const token = Cookies.get('auth_token');
        const payload = {
            title: productName,
            description,
            price,
            discount,
            categories: selectedCategories,
            images,
            variants
        };
        try {
            const response = await axios.post(`/api/update-product/${uid}/`, payload, {
                headers: { Authorization: `Token ${token}`, 'Content-Type': 'application/json' },
            });
            
            // Axios response handling
            if (response.status === 200) {
                setProduct(response.data); // Axios already parses JSON
                setHasUnsavedChanges(false);
                setError(null); // Clear any existing errors
                triggerNotification(); // Show success notification
            } else {
                throw new Error('Failed to save product.');
            }
        } catch (error) {
            console.error('Save error:', error);
            setError('Failed to save product');
        }
    };

    const handleDeleteVariant = (tempId) => {
        console.log("Deleting variant:", tempId);
        setVariants(prev => prev.filter(v => v.tempId !== tempId));
        setHasUnsavedChanges(true);
    };

    return (
        <>
            <main className="lg:pl-72">
                <div className="xl:pr-96 px-4 py-6 sm:px-6 lg:px-8 lg:py-6">
                    <nav aria-label="Breadcrumb">
                        <ol role="list" className="flex items-center space-x-4">
                            <li>
                                <a href="/m/products" className="text-gray-400 hover:text-gray-500">
                                    <span className="text-sm font-medium text-gray-500 hover:text-gray-700">Products</span>
                                </a>
                            </li>
                            <li className="flex items-center">
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                    {isLoading ? "Loading" : product?.title}
                                </a>
                            </li>
                        </ol>
                    </nav>

                    <div className="mt-4 bg-white border p-6 rounded-md">
                        {isLoading ? (
                            <h1 className="text-base font-semibold text-gray-900">Loading...</h1>
                        ) : error ? (
                            <h1 className="text-base font-semibold text-red-600">Error loading product</h1>
                        ) : (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Product title</label>
                                        <input
                                            type="text"
                                            value={productName}
                                            onChange={handleFieldChange(setProductName)}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                            placeholder="Enter product name"
                                        />
                                        <label className="mt-4 block text-sm font-medium text-gray-700">Description</label>
                                        <textarea
                                            value={description}
                                            onChange={handleFieldChange(setDescription)}
                                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                            placeholder="Enter product description"
                                            rows={4}
                                        />

                                        <label className="mt-8 block text-sm font-medium text-gray-700">Selected categories</label>
                                        <div className={`flex flex-wrap gap-2 mt-2`}>
                                            {selectedCategories.map((id) => {
                                                const category = categories.find((cat) => cat.id === id);
                                                return (
                                                    <span key={id} className="inline-flex items-center gap-x-0.5 rounded-md bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-800">
                                                        {category?.title}
                                                        <button
                                                            type="button"
                                                            onClick={() => toggleCategorySelection(id)}
                                                            className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-indigo-600/20"
                                                        >
                                                            <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-indigo-800/50 group-hover:stroke-indigo-800/75">
                                                                <path d="M4 4l6 6m0-6l-6 6" />
                                                            </svg>
                                                        </button>
                                                    </span>
                                                );
                                            })}
                                        </div>
                                        <button
                                            onClick={openCategoryModal}
                                            className={`${selectedCategories.length > 0 ? 'mt-2' : ''} rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
                                        >
                                            Manage categories
                                        </button>

                                        <h3 className="mt-8 text-sm font-medium text-gray-700">Product variations</h3>
                                        {variants.length > 0 ? (
                                            <ul>
                                                {variants.map((variant) => (
                                                    <li key={variant.tempId} className="flex justify-between items-center p-4 border rounded-md my-2">
                                                        <div className="flex space-x-6">
                                                            <p className="text-sm font-medium text-gray-900">Price: {variant.price}</p>
                                                            <p className="text-sm font-medium text-gray-900">Stock: {variant.stock_quantity}</p>
                                                            <p className="text-sm font-medium text-gray-900">SKU: {variant.sku || 'No SKU'}</p>
                                                        </div>
                                                        {variant.attributes?.length > 0 && (
                                                            <div className="mt-1 text-sm text-gray-600">
                                                                Attributes: {variant.attributes.map(attr => 
                                                                    `${attr.title}: ${attr.value}`
                                                                ).join(', ')}
                                                            </div>
                                                        )}
                                                        <div className="flex space-x-4">
                                                            <button
                                                                className="font-semibold text-indigo-600 hover:text-indigo-900"
                                                                onClick={() => openVariantModal(variant)}
                                                            >
                                                                Update
                                                            </button>
                                                            <button
                                                                className="font-semibold text-red-600 hover:text-red-900"
                                                                onClick={() => handleDeleteVariant(variant.tempId)}
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p className="text-sm text-gray-500">No variations set.</p>
                                        )}

                                        <button className="mt-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={handleAddVariant}>Create variant</button>
                                    </div>

                                    <div>
                                        <div className="mt-4 justify-center rounded-lg border bg-gray-50 px-4 py-4 text-center">
                                            <div className="grid grid-cols-3 gap-4">
                                                {images.map((image, index) => (
                                                    <div key={index} className="relative">
                                                        <img src={image.image_url} alt={`Product ${index}`} className="w-full h-32 object-cover rounded-md" />
                                                        <button
                                                            type="button"
                                                            onClick={() => handleRemoveImage(index)}
                                                            className="absolute top-2 left-2 text-white font-bold text-2xl rounded-full h-8 w-8 flex items-center justify-center bg-gray-700"
                                                        >
                                                            {React.createElement(IconMap['XMarkIcon'], { className: 'h-5 w-5 text-white', 'aria-hidden': 'true' })}
                                                        </button>
                                                    </div>
                                                ))}

                                                {images.length > 0 && images.length < 3 && (
                                                    <div className="relative flex flex-col items-center justify-center w-full h-32 rounded-md hover:border-blue-500 hover:border-solid hover:text-blue-500 border-2 border-dashed border-slate-300 text-sm leading-6 text-slate-900 font-medium py-3 h-full hover:bg-white">
                                                        {React.createElement(IconMap['PlusIcon'], { className: 'h-8 w-8 text-gray-500', 'aria-hidden': 'true' })}
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            multiple
                                                            onChange={handleImageUpload}
                                                            className="absolute inset-0 opacity-0 cursor-pointer"
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            {images.length === 0 && (
                                                <div>
                                                    {React.createElement(IconMap['PhotoIcon'], { className: 'mx-auto h-12 w-12 text-gray-300', 'aria-hidden': 'true' })}
                                                    <label htmlFor="image-upload" className="relative underline cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                                                        Upload a file
                                                    </label>
                                                    <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 1MB</p>
                                                </div>
                                            )}

                                            <input
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                id="image-upload"
                                                onChange={handleImageUpload}
                                                className="hidden"
                                            />
                                        </div>
                                    </div>

                                </div>

                                <button onClick={handleSaveProduct} className={`mt-6 px-4 py-2 rounded-md inline-flex items-center justify-center text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${hasUnsavedChanges ? 'bg-indigo-600 text-white hover:bg-indigo-500' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`} disabled={!hasUnsavedChanges}>
                                    Publish
                                </button>
                            </>
                        )}
                    </div>
                    <Notification 
                        show={showNotification} 
                        onClose={() => setShowNotification(false)}
                    />
                </div>
            </main>

            <ReusableModal
                isOpen={isCategoryModalOpen}
                closeModal={() => setCategoryModalOpen(false)}
                title="Manage Categories"
                handleSave={handleSaveCategory}
                isCategoryModal={true}  // Indicates it's a category modal
                isCreatingNewCategory={isCreatingNewCategory} // Pass the state here
                toggleMode={toggleCategoryModalView} // Pass the toggle function
                fieldValues={isCreatingNewCategory ? newCategory : selectedCategories}
                setFieldValues={isCreatingNewCategory ? setNewCategory : setSelectedCategories}
                categories={categories}
            />

            <ReusableModal
                isOpen={isVariantModalOpen}
                closeModal={() => setIsVariantModalOpen(false)}
                title={currentVariant ? "Update Variant" : "Create Variant"}
                handleSave={() => handleSaveVariant()} // Ensure this is called
                isCategoryModal={false}
                fieldValues={variantFieldValues}
                setFieldValues={setVariantFieldValues}
            />
        </>
    );
}
