import React from 'react';
import Hub from '../../pages/Hub';
import ProductLayout from '../../pages/ProductLayout';
import { Handle } from 'reactflow';

const CustomNode = ({ data, isSelected, app_metadata }) => {
  const topText = {
    position: 'absolute',
    top: '-2rem',
    left: '12rem',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
  };

  return (
    <div className={`canvas mockup-phone border-primary shadow hover:shadow-md rounded-md bg-white border-2 ${
      isSelected ? 'border-indigo-500' : 'border-gray-200'
    }`}>
      <div className="text-sm text-gray-600" style={topText}>{data.asset_type}</div>
      <div className="camera"></div>
      <div className="display bg-gray-50">
        <div className="content artboard phone w-[375px] h-[667px]">
          {data.asset_type === 'App' ? (
            <Hub 
              previewMode={true} 
              previewData={data.uid === '1' ? app_metadata : data.previewData || { uid: data.uid }} 
            />
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-500">Preview not available for {data.asset_type}</p>
            </div>
          )}
        </div>
      </div>
      <Handle 
        type="source" 
        position="right" 
        id="right" 
        style={{ top: '50%', background: '#555' }} 
      />
      <Handle 
        type="target" 
        position="left" 
        id="left" 
        style={{ top: '50%', background: '#555' }} 
      />
    </div>
  );
};

export default CustomNode;
